var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"problem-student-container"},[_c('div',{staticClass:"problem-student-header"},[_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',[_vm._v("理论题")]),_c('el-breadcrumb-item',{attrs:{"to":{ path:'/trainAdmin/problemAnalysis/index' }}},[_vm._v("问答评分")]),_c('el-breadcrumb-item',[_vm._v("学生评分")])],1)],1),_c('el-table',{staticStyle:{"width":"100%","margin-top":"23px"},attrs:{"data":_vm.tableData,"height":"100%","border":"","cell-style":{ color: 'rgba(52, 52, 65, 1)', borderRight: 'unset' },"header-cell-style":{
      color: 'rgba(19, 19, 27, 1)',
      background: '#F3F4FA',
      borderRight: 'unset',
    }}},[_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('el-form',{staticClass:"demo-table-expand",attrs:{"label-position":"left","inline":""}},_vm._l((props.row.children),function(item,index){return _c('div',{key:index},[_c('el-form-item',{staticClass:"train-container"},[_c('span',[_vm._v("第"+_vm._s(item.times_key)+"次")])]),_c('el-form-item',{staticClass:"train-status"},[_c('span',{class:[item.status == 0 ? 'train-status-active' : '']},[_vm._v(_vm._s(item.status == 0 ? "进行中" : "已完成"))])]),_c('el-form-item',{staticClass:"operation"},[_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.goToDetail(item)}}},[_c('span',{class:[
                    item.marked == 0 && item.status == 1
                      ? 'operation-active'
                      : 'operation-normal',
                  ]},[_vm._v(_vm._s(item.marked == 0 && item.status == 1 ? "可评分" : item.marked == 1 && item.status == 1 ? "已评分" : item.status == 0 ? "不可评分" : ""))])])],1)],1)}),0)]}}])}),_c('el-table-column',{attrs:{"label":"学生","prop":"student_name","align":"center"}}),_c('el-table-column',{attrs:{"label":"训练次数","prop":"total","align":"center"}}),_c('el-table-column',{attrs:{"label":"训练状态","prop":"desc","align":"center"}}),_c('el-table-column',{attrs:{"label":"操作","prop":"desc","align":"center"}})],1),_c('el-pagination',{staticClass:"pages-center",attrs:{"current-page":_vm.listPages.currentPageNum,"page-size":_vm.listPages.eachPageNum,"layout":"prev, pager, next, jumper","total":_vm.listPages.total},on:{"current-change":_vm.pageCurrentChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }