<template>
  <div class="problem-student-container">
    <div class="problem-student-header">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>理论题</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path:'/trainAdmin/problemAnalysis/index' }">问答评分</el-breadcrumb-item>
        <el-breadcrumb-item>学生评分</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-table
      :data="tableData"
      height="100%"
      style="width: 100%; margin-top: 23px"
      border
      :cell-style="{ color: 'rgba(52, 52, 65, 1)', borderRight: 'unset' }"
      :header-cell-style="{
        color: 'rgba(19, 19, 27, 1)',
        background: '#F3F4FA',
        borderRight: 'unset',
      }"
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-form label-position="left" inline class="demo-table-expand">
            <div v-for="(item, index) in props.row.children" :key="index">
              <el-form-item class="train-container">
                <span>第{{ item.times_key }}次</span>
              </el-form-item>
              <el-form-item class="train-status">
                <span
                  :class="[item.status == 0 ? 'train-status-active' : '']"
                  >{{ item.status == 0 ? "进行中" : "已完成" }}</span
                >
              </el-form-item>
              <el-form-item class="operation">
                <el-button @click="goToDetail(item)" type="text" size="small">
                  <span
                    :class="[
                      item.marked == 0 && item.status == 1
                        ? 'operation-active'
                        : 'operation-normal',
                    ]"
                    >{{
                      item.marked == 0 && item.status == 1
                        ? "可评分"
                        : item.marked == 1 && item.status == 1
                        ? "已评分"
                        : item.status == 0
                        ? "不可评分"
                        : ""
                    }}</span
                  >
                </el-button>
              </el-form-item>
            </div>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="学生" prop="student_name" align="center">
      </el-table-column>
      <el-table-column label="训练次数" prop="total" align="center">
      </el-table-column>
      <el-table-column label="训练状态" prop="desc" align="center">
      </el-table-column>
      <el-table-column label="操作" prop="desc" align="center">
      </el-table-column>
    </el-table>
    <el-pagination
      class="pages-center"
      :current-page="listPages.currentPageNum"
      :page-size="listPages.eachPageNum"
      layout="prev, pager, next, jumper"
      :total="listPages.total"
      @current-change="pageCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { showProblemListData, showAnswer } from "@/utils/apis";
export default {
  data() {
    return {
      listPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      tableData: [],
      pid:this.$route.query.pid || null,
    };
  },
  mounted() {
    this.showProblemStuListData();
  },
  methods: {
    showProblemStuListData() {
      let params = {
        id: this.pid,
        paging: "1",
        pageSize: this.listPages.eachPageNum,
        page: this.listPages.currentPageNum,
      };
      showProblemListData(params).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.list;
          this.listPages.total = res.data.total;
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    // 切换分页
    pageCurrentChange(val) {
      this.listPages.currentPageNum = val;
      this.showProblemStuListData();
    },
    goToDetail(row) {
      //评完分还能进去修改
      // if (row.marked == 0 && row.status == 1) {
      //
      // }
      this.$router.push({
        path:"/trainAdmin/problemAnalysis/problemStudentAnswerList",
        query:{
          id:row.id,
          user_id:row.user_id,
          student_name:row.student_name,
          pid:row.train_id
        }
      })
    },
  },
};
</script>

<style  lang="scss" scoped>
.problem-student-container {
  padding: 30px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  ::v-deep .el-table th.el-table__cell {
    background: rgba(245, 245, 251, 1);
  }
  ::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner {
    color: rgb(0, 0, 0);
    font-weight: bold;
  }
  .demo-table-expand {
    font-size: 0;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
  .train-status {
    position: absolute !important;
    left: 62.5% !important;
  }
  .train-status-active {
    color: rgba(59, 180, 0, 1);
  }
  .train-container {
    position: relative;
    left: 38.5% !important;
  }
  .operation {
    position: relative;
    left: 36.7% !important;
  }
  .operation-active {
    color: rgba(17, 34, 216, 1);
    font-weight: bold;
  }
  .operation-normal {
    color: rgba(52, 52, 65, 1);
  }
  .pages-center {
    text-align: center;
  }
  ::v-deep .el-breadcrumb__item {
    .el-breadcrumb__inner.is-link {
      font-weight: 400;
      color: #606266;
    }
  }
  .pages-center {
    text-align: center;
    margin-top: 20px;
  }
}
</style>